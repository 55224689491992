import { useEffect, useState } from "react";
import { useGetCitiesByStateLazyQuery, useGetStatesQuery } from "../../../lib/apollo/graphql/generated";

/**
 * - `name`: Name of city (null if for all cities)
 * - `code`: Code of city (null if for all cities)
 * - `stateCode`: Code of state
 * - `stateName`: Name of state
 */
export type City = {
  name: string | null;
  code: string | null;
  stateCode: string;
  stateName: string;
};

export const DEFAULT_CITY: City = {
  name: "",
  code: "",
  stateCode: "",
  stateName: "",
};

export const useRegionSelector = (onAdd: (cities: City[]) => void) => {
  /**
   * - Name/Code of the state that is currently selected
   * - Set to `null` if no state has been selected
   */
  const [city, setCity] = useState<City>(DEFAULT_CITY);

  const [getCitiesByState, { loading: isCitiesLoading, data: cityData }] = useGetCitiesByStateLazyQuery();
  const { loading: isStatesLoading, data: statesData } = useGetStatesQuery();

  useEffect(() => {
    if (city?.stateCode) {
      getCitiesByState({
        variables: {
          stateCode: city.stateCode,
        },
      });
    }
  }, [city.stateCode, getCitiesByState]);

  const onStateSelect = (newState: string) => {
    const parsed = JSON.parse(newState);

    setCity(previous => ({
      ...previous,
      stateCode: parsed.code,
      stateName: parsed.name,
    }));
  };

  const onCitySelect = (newCity: string) => {
    if (newCity === "ALL") {
      setCity(previous => ({
        ...previous,
        name: null,
        code: null,
      }));

      return;
    } else {
      const parsed: City = JSON.parse(newCity);

      setCity(previous => ({
        ...previous,
        ...parsed,
      }));
    }
  };

  const reset = () => {
    setCity(DEFAULT_CITY);
  };

  const onRegionAdd = () => {
    if (city.stateCode && !city.code) {
      const newCities: City[] = [];

      if (!cityData?.getCitiesByState) return;

      for (const fetchedCity of cityData.getCitiesByState) {
        const newCity = {
          code: fetchedCity?.cityCode!,
          name: fetchedCity?.name!,
          stateCode: city.stateCode,
          stateName: city.stateName,
        };

        newCities.push(newCity);
      }

      onAdd(newCities);
    } else {
      onAdd([city]);
    }

    reset();
  };

  return {
    models: {
      city,
      isStatesLoading,
      states: statesData?.getStates,
      isCitiesLoading,
      cities: cityData?.getCitiesByState,
    },
    operations: {
      onStateSelect,
      onCitySelect,
      reset,
      onRegionAdd,
    },
  };
};

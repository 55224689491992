import styled from "styled-components";
import { City, useRegionSelector } from "./RegionSelector.hook";
import Loader from "./Loader";
import BasicButton from "./BasicButton";

interface RegionSelectorProps {
  disabled?: boolean;
  onAdd: (cities: City[]) => void;
  onSelectAllRegions: () => void;
}

function RegionSelector({ onSelectAllRegions, onAdd, disabled = false }: RegionSelectorProps) {
  const {
    models: { city, isStatesLoading, states, cities },
    operations: { onStateSelect, onCitySelect, onRegionAdd },
  } = useRegionSelector(onAdd);

  if (isStatesLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <Wrapper>
      {/* 2. For selecting a state */}
      <RegionSelectorWrapper>
        <RegionLabel>시/도</RegionLabel>
        <RegionDropdown id="state" onChange={event => onStateSelect(event.target.value)} disabled={disabled}>
          <option value="PLACEHOLDER" disabled selected={!city?.stateCode}>
            시/도를 선택해 주세요.
          </option>
          {states?.map(state => (
            <option
              key={state?.stateCode || state?.stateCode}
              value={JSON.stringify({
                code: state?.stateCode,
                name: state?.name,
              })}
            >
              {state?.name}
            </option>
          ))}
        </RegionDropdown>
      </RegionSelectorWrapper>
      {/* 2. For selecting a city */}
      <RegionSelectorWrapper>
        <RegionLabel>시/군/구</RegionLabel>
        <RegionDropdown id="city" onChange={event => onCitySelect(event.target.value)} disabled={disabled}>
          <option value="PLACEHOLDER" disabled selected={!city.stateCode}>
            시/군/구를 선택해 주세요.
          </option>
          {cities && city?.stateCode ? (
            [
              <option value="ALL" selected={city.code === "ALL"}>
                {city?.stateName} 전체
              </option>,
              ...cities.map(city => (
                <option
                  key={city?.cityCode || city?.name}
                  value={JSON.stringify({
                    name: city?.name || "",
                    code: city?.cityCode || "",
                  })}
                >
                  {city?.name}
                </option>
              )),
            ]
          ) : (
            <option disabled>시/도를 우선 선택해 주세요.</option>
          )}
        </RegionDropdown>
      </RegionSelectorWrapper>
      <ButtonWrapper>
        <BasicButton text={disabled ? "맞춤 지역 선택" : "전체글로 배포"} width="10rem" onClick={onSelectAllRegions} />
        <BasicButton
          text="지역 추가"
          disabled={!city.stateCode && disabled}
          width="10rem"
          onClick={() => onRegionAdd()}
        />
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  gap: theme.spacing.small,
}));

const RegionSelectorWrapper = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
}));

const RegionLabel = styled.label(() => ({
  width: 80,
  flexShrink: 0,
  fontWeight: "bold",
}));

const RegionDropdown = styled.select(() => ({
  width: "100%",
  padding: 5,
  borderRadius: 5,
  fontSize: "1rem",
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  marginTop: theme.spacing.middle,
  display: "flex",
  gap: theme.spacing.normal,
}));

export default RegionSelector;

import styled from "styled-components";
import useAddInfoModal from "./AddInfoModal.hook";
import Modal from "../../common/components/Modal";
import Loader from "../../common/components/Loader";
import Editor from "../../common/components/Editor";
import RegionSelector from "../../common/components/RegionSelector";
import BasicButton from "../../common/components/BasicButton";

interface AddInfoModalProps {
  isOpen: boolean;
  selectedInfoId: string;
  onClose: () => void;
}

function AddInfoModal({ isOpen, selectedInfoId, onClose }: AddInfoModalProps) {
  const {
    models: { loading, state, data, updateLoading, isForAllRegions, validatedBody },
    operations: {
      changeCaregiverInfoInput,
      changeMedia,
      onMediaStateRemove,
      onMediaRemove,
      onSelectAllRegions,
      onRemoveCity,
      onAddCity,
      onSubmit,
    },
  } = useAddInfoModal(selectedInfoId, onClose);

  if (loading) {
    return (
      <Modal title="정보글 등록" open={isOpen} onClose={onClose} width="80%" height="95%">
        <Loader />
      </Modal>
    );
  }

  const renderImagePreviews = (media: any, isData: boolean) => {
    return media.map((item: any, index: any) => (
      <div key={index} style={{ position: "relative", margin: "5px" }}>
        <DeleteButton
          onClick={() => {
            if (isData) onMediaRemove(item?.id);
            else onMediaStateRemove(index);
          }}
        >
          X
        </DeleteButton>
        <img
          src={isData ? item.uri : URL.createObjectURL(item)}
          alt={`Preview ${index + 1}`}
          style={{ maxWidth: "200px", maxHeight: "200px", margin: "5px" }}
        />
      </div>
    ));
  };

  return (
    <Modal title="정보글 등록" open={isOpen} onClose={onClose} width="80%" height="95%">
      <Wrapper>
        <FormWrapper>
          <ContentsWrapper>
            <Editor value={state?.body} onChange={changeCaregiverInfoInput} height="350px" />
            <ImageWrapper>
              <ImageInputWrapper>
                <HiddenFileInput type="file" multiple accept="image/*" onChange={changeMedia} />
                <ImageInputLabel htmlFor="hiddenFileInput">이미지 선택을 위해 클릭해 주세요.</ImageInputLabel>
              </ImageInputWrapper>
              <ImagePreviewsContainer>
                {!!data?.media && data?.media?.length > 0 && renderImagePreviews(data?.media, true)}
                {!!state?.mediaState && state?.mediaState?.length > 0 && renderImagePreviews(state?.mediaState, false)}
                {!state?.mediaState?.length && !data?.media?.length && <NoImagesMessage>No Images Yet</NoImagesMessage>}
              </ImagePreviewsContainer>
            </ImageWrapper>
          </ContentsWrapper>
          <RegionsWrapper>
            <SelectedRegions>
              <RegionsTitle>배포될 지역들</RegionsTitle>
              <Regions>
                {state.cities && state.cities.length ? (
                  state.cities?.map((city, index) => {
                    return (
                      <RegionItem>
                        <span>
                          {city.stateName} {city.name && city.stateCode ? city.name : "전체"}
                        </span>
                        <DeleteRegionButton
                          className="bi bi-trash3"
                          onClick={() => onRemoveCity(index)}
                        ></DeleteRegionButton>
                      </RegionItem>
                    );
                  })
                ) : (
                  <li>{isForAllRegions ? "전체글로 배포될 예정입니다." : "아직 선택된 지역이 없습니다."}</li>
                )}
              </Regions>
            </SelectedRegions>
            <RegionSelector onAdd={onAddCity} onSelectAllRegions={onSelectAllRegions} disabled={isForAllRegions} />
          </RegionsWrapper>
        </FormWrapper>
        <ButtonWrapper>
          <BasicButton
            text={!updateLoading ? "제출" : ""}
            icon={updateLoading && <Loader />}
            disabled={!validatedBody || updateLoading}
            width="30%"
            onClick={onSubmit}
          />
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: theme.spacing.large,
}));

const ButtonWrapper = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "auto",
}));

const FormWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.large,
  width: "100%",
}));

const ContentsWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "65%",
  justifyContent: "space-between",
}));

const RegionsWrapper = styled.div(() => ({
  width: "35%",
}));

const SelectedRegions = styled.div(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing.small,
}));

const RegionItem = styled.li(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.small,
  alignItems: "center",
}));

const DeleteRegionButton = styled.i(({ theme }) => ({
  fontSize: 12,
  cursor: "pointer",
  transition: "0.3s ease-out",

  "&:hover": {
    color: theme.color.error,
  },
}));

const RegionsTitle = styled.label(() => ({
  fontWeight: "bold",
}));

const Regions = styled.ul(({ theme }) => ({
  width: "100%",
  color: theme.color.darkGray,
  listStyle: "none",
  paddingLeft: 0,
}));

const ImageWrapper = styled.div(() => ({
  flex: 1,
  marginTop: 80,
  width: "100%",
}));

const ImageInputWrapper = styled.div(({ theme }) => ({
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  borderRadius: "4px",
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
}));

const HiddenFileInput = styled.input(({ theme }) => ({
  opacity: 0,
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer",
  zIndex: 1,
  top: 0,
  left: 0,
  overflow: "hidden",
}));

const ImageInputLabel = styled.label(({ theme }) => ({
  border: `1px solid ${theme.color.gray}`,
  width: "100%",
  cursor: "pointer",
  transition: "0.1s ease-in",
  display: "block",
  padding: "8px",
  borderRadius: "4px",
  color: theme.color.darkGray,
  textAlign: "center",

  "&:hover": {
    border: `1px solid ${theme.color.darkNavy}`,
  },
}));

const ImagePreviewsContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  marginTop: "10px",
  maxHeight: "300px",
  overflowX: "auto",
  border: `2px dashed ${theme.color.gray}`,
  minHeight: 200,
}));

const DeleteButton = styled.button(({ theme }) => ({
  position: "absolute",
  background: "none",
  border: "none",
  color: theme.color.gray,
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "bold",
  margin: "5px",
  padding: 0,
  transition: "0.1s ease-in",
  right: 10,
  top: 10,

  "&:hover": {
    color: theme.color.error,
  },
}));

const NoImagesMessage = styled.p(({ theme }) => ({
  color: theme.color.gray,
  fontStyle: "italic",
  width: "100%",
  height: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: 0,
}));

export default AddInfoModal;

import styled from "styled-components";
import Modal from "../../common/components/Modal";
import TwoButtons from "../../common/components/TwoButtons";
import useDeleteInfoModal from "./DeleteInfoModal.hook";
import Loader from "../../common/components/Loader";

interface DeleteInfoModalProps {
  isOpen: boolean;
  infoId: string;
  onClose: () => void;
}

function DeleteInfoModal({ isOpen, onClose, infoId }: DeleteInfoModalProps) {
  const {
    models: { loading },
    operations: { onDeleteInfo },
  } = useDeleteInfoModal(infoId, onClose);

  return (
    <Modal title="정보글 삭제" open={isOpen} onClose={onClose} width="500px" height="500px">
      <ModalContents>
        <WarningMessage>삭제된 정보는 복구할 수 없습니다.</WarningMessage>
        <WarningMessage>정말 삭제하시겠습니까?</WarningMessage>
        <TwoButtons
          text1={loading ? "" : "삭제"}
          icon1={loading && <Loader />}
          text2="취소"
          hoverBackground1="lightGreen"
          hoverBackground2="lightRed"
          buttonWidth="40%"
          fontWeight="bold"
          onClick1={onDeleteInfo}
          onClick2={onClose}
        />
      </ModalContents>
    </Modal>
  );
}

const ModalContents = styled.div(({ theme }) => ({
  marginTop: theme.spacing.regular,
  width: "100%",
}));

const WarningMessage = styled.p(({ theme }) => ({
  color: theme.color.darkGray,
  fontSize: 18,
  margin: 0,
  textAlign: "center",

  "&:last-of-type": {
    marginTop: 10,
    marginBottom: "50%",
    fontWeight: "bold",
  },
}));

export default DeleteInfoModal;

import styled from "styled-components";

const ESSENTIAL_BUTTON_COLORS = {
  green: "#44cf44de",
  lightGreen: "#67df67f3",
  red: "#d14c4cd1",
  lightRed: "#ea4c4cdf",
  navy: "#293241",
  lightNavy: "#3d5a80",
  yellow: "#d8e156",
  lightYellow: "#e5ee61",
  gray: "#c5c5c5df",
  lightGray: "#f2f2f2df",
};

interface BasicButtonProps {
  text?: string;
  icon?: React.ReactNode;
  changeOnHover?: boolean;
  backgroundColor?: string;
  color?: string;
  hoverBackgroundColor?: string;
  hoverColor?: string;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  type?: string;
}

interface ButtonProps {
  $backgroundColor: string;
  $changeOnHover?: boolean;
  $color: string;
  $hoverBackgroundColor: string;
  $hoverColor: string;
  $width?: string;
  $height?: string;
  $fontWeight?: string;
  $fontSize: string;
  type?: string;
}

function BasicButton({
  text,
  icon,
  color = "unpassed",
  backgroundColor = "unpassed",
  hoverBackgroundColor = "unpassed",
  hoverColor = "unpassed",
  onClick,
  changeOnHover = true,
  width = "100px",
  height = "auto",
  fontWeight = "inherit",
  disabled = false,
  fontSize = "14px",
  type,
}: BasicButtonProps) {
  return (
    <Button
      onClick={onClick}
      $backgroundColor={backgroundColor}
      $color={color}
      $hoverBackgroundColor={hoverBackgroundColor}
      $hoverColor={hoverColor}
      $changeOnHover={changeOnHover}
      $width={width}
      $height={height}
      $fontWeight={fontWeight}
      $fontSize={fontSize}
      disabled={disabled}
      type={type}
    >
      {icon ? icon : text}
    </Button>
  );
}

const Button = styled.button<ButtonProps>(
  ({
    theme,
    $backgroundColor,
    $color,
    $hoverBackgroundColor,
    $fontWeight,
    $hoverColor,
    $width,
    $height,
    $changeOnHover,
    $fontSize,
    disabled,
  }) => ({
    border: "none",
    borderRadius: 5,
    width: $width,
    height: $height,
    minHeight: "2.5rem",
    backgroundColor:
      $backgroundColor === "unpassed"
        ? ESSENTIAL_BUTTON_COLORS.navy
        : ESSENTIAL_BUTTON_COLORS[$backgroundColor as keyof typeof ESSENTIAL_BUTTON_COLORS],
    color: $color === "unpassed" ? "white" : $color,
    padding: theme.spacing.small,
    transition: "0.1s ease-in",
    fontWeight: $fontWeight,
    fontSize: $fontSize,
    cursor: `${disabled ? "auto" : "pointer"}`,

    ...($changeOnHover && !disabled
      ? {
          "&:hover": {
            color: $hoverColor === "unpassed" ? "white" : $hoverColor,
            backgroundColor:
              $hoverBackgroundColor === "unpassed"
                ? ESSENTIAL_BUTTON_COLORS.lightNavy
                : ESSENTIAL_BUTTON_COLORS[$hoverBackgroundColor as keyof typeof ESSENTIAL_BUTTON_COLORS],
          },
        }
      : {}),
  })
);

export default BasicButton;
